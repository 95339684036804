<template>
  <div id="app">
  <div class="audioDomArea" style="display: none;">
    <audio class="audioDomError">
      <source src="https://eshop1212-oss-prod.oss-cn-shenzhen.aliyuncs.com/test-eshop/0/d5f0b59daa984fe79843c86350ac9d51.mp3" type="audio/mp3">
      您的浏览器不支持 audio 元素。
    </audio>
    <audio class="audioDomSuccess">
      <source src="https://eshop1212-oss-prod.oss-cn-shenzhen.aliyuncs.com/test-eshop/0/a72ecdf273e541619e3a28ceaa360672.mp3" type="audio/mp3">
      您的浏览器不支持 audio 元素。
    </audio>
  </div>
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {}
  },
  computed: {},
  watch: {
    // '$route': {
    //   handler: function (to, from) {
    //     console.log(to, 'to')
    //     console.log(from, 'from')
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    '$route' (to, from) {
      sessionStorage.setItem('routeTo', JSON.stringify(to.path))
      sessionStorage.setItem('routeFrom', JSON.stringify(from.path))
    }
  },
  created() {
  },
  methods: {}
}
</script>
<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.audioDomArea {
  position: absolute;
  left: -999px;
  top: 0;
  z-index: 20;
}
.my-swiper-pagination-bullet {
  position: relative;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 2px;
  background-color: rgba(0, 0, 0, 0.3);
}
.my-swiper-pagination-bullet-active {
  background-color: rgba(255, 255, 255, 0.8);
}
.my-swiper-pagination-rect {
  position: relative;
  display: inline-block;
  width: 17px;
  height: 7px;
  margin: 0 3px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.3);
}
.my-swiper-pagination-rect-active {
  background-color: rgba(255, 255, 255, 0.8);
}
.swiper-wrapper {
  align-items: center;
}
.swiperSlideNormal.swiper-slide-active, .swiperSlideShortHeight.swiper-slide-active {
  height: 100% !important;
}

// .swiper-wrapper .swiperSlideDisplayType3.swiper-slide-active {
//   height: 100% !important;
// }
.swiper-wrapper .swiperSlideDisplayType3.swiper-slide-active {
  height: 100% !important;
}
.is-error .el-input-number__increase,
.is-success .el-input-number__increase {
  right: 1px!important;
}
.avue-crud span.avue-crud__tip {
  height: auto;
}
.default .el-popover {
  border: 1px solid #DCDFE6;
}
.elSliderForm {
  .el-form-item__content {
    padding-bottom: 20px;
  }
}
.el-slider__marks-text {
  word-break: keep-all;
}

// JSON CSS start -------------
.json-theme-dark {
  border-radius: 10px;
  background: #272823;
  white-space: nowrap;
  color: #fff;
  font-size: 14px;
  font-family: Consolas, Menlo, Courier, monospace;

  .jv-ellipsis {
    color: #999;
    background-color: #eee;
    display: inline-block;
    line-height: 0.9;
    font-size: 0.9em;
    padding: 0px 4px 2px 4px;
    border-radius: 3px;
    vertical-align: 2px;
    cursor: pointer;
    user-select: none;
  }
  .jv-button { color: #49b3ff }
  .jv-key { color: #6e9354 }
  .jv-item {
    &.jv-array { color: #FAD649 }
    &.jv-boolean { color: #A686F7 }
    &.jv-function { color: #067bca }
    &.jv-number { color: #A686F7 }
    &.jv-number-float { color: #A686F7 }
    &.jv-number-integer { color: #A686F7 }
    &.jv-object { color: #CC78D1 }
    &.jv-undefined { color: #e08331 }
    &.jv-string {
      color: yellow;
      word-break: break-word;
      white-space: normal;
      a {
        color: #2d8cf0;
      }
    }
  }
  .jv-code {
    .jv-toggle {
      background-color: #fff;
      &:before {
        padding: 0px 2px;
        border-radius: 2px;
      }
      &:hover {
        &:before {
          background: #eee;
        }
      }
    }
  }
}
// JSON CSS end -------------
div.el-form-item__error {
  color: #ed4014;
  font-weight: 900;
}
.el-popconfirm {
  div.el-popconfirm__action {
    margin-top: 10px;
  }
}
</style>
