var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "app" } }, [_vm._m(0), _c("router-view")], 1)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "audioDomArea", staticStyle: { display: "none" } },
      [
        _c("audio", { staticClass: "audioDomError" }, [
          _c("source", {
            attrs: {
              src: "https://eshop1212-oss-prod.oss-cn-shenzhen.aliyuncs.com/test-eshop/0/d5f0b59daa984fe79843c86350ac9d51.mp3",
              type: "audio/mp3",
            },
          }),
          _vm._v(" 您的浏览器不支持 audio 元素。 "),
        ]),
        _c("audio", { staticClass: "audioDomSuccess" }, [
          _c("source", {
            attrs: {
              src: "https://eshop1212-oss-prod.oss-cn-shenzhen.aliyuncs.com/test-eshop/0/a72ecdf273e541619e3a28ceaa360672.mp3",
              type: "audio/mp3",
            },
          }),
          _vm._v(" 您的浏览器不支持 audio 元素。 "),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }