import Vue from 'vue'
import { dateStr, formatDateTime } from '@/util/chatUtils.js'
import echarts from 'echarts'
import { weBtoa, weAtob } from './jwt.js'

/**
 * 权限检查方法
 */
// Vue.prototype.$_has = function(value) {
//   const permissions = store.state.user.permissions
//   if (!permissions[value]) {
//     return false
//   }
//   return true
// }
// 将对象转换为 url 的 query 参数 eg: { id: 1, name: '2' } ---> ?id=1&name=2
export const _spliceObjToUrlFn =  (obj, isShowQuestionMark = false, firstStr = '', joinStr = '&') => {
  if (!obj) {
    throw new Error('函数 _spliceObjToUrlFn 缺失参数')
  }
  if (Object.prototype.toString.call(obj) !== '[object Object]') {
    throw new Error('函数 _spliceObjToUrlFn 的 参数类型必须是对象')
  }
  if (Object.keys(obj).length < 1) {
    return ''
    // throw new Error('函数 _spliceObjToUrlFn 的 参数 不能为空对象')
  }
  let urlStr = ''
  isShowQuestionMark && (urlStr = '?')
  Object.keys(obj).forEach(key => {
    // urlStr += key + '=' + (obj[key] === undefined ? '' : obj[key]) + joinStr
    urlStr += key + '=' + (!_isHasValueFn(obj[key]) ? '' : obj[key]) + joinStr
  })
  urlStr = urlStr.substr(0, urlStr.lastIndexOf(joinStr))
  urlStr = firstStr + urlStr
  return urlStr
}

// 是否有值 ['true 有值', 'false 没有值']
export const _isHasValueFn = (val) => {
  if (typeOf(val) === 'array') {
    return val.length > 0
  } else if (typeOf(val) === 'object') {
    return Object.keys(val).length > 0
  } else {
    return val !== '' && val !== null && val !== undefined && val !== 'null' && val !== 'undefined'
  }
}

// 数据类型判断
export const typeOf = function (obj) {
  const toString = Object.prototype.toString
  const map = {
    '[object Date]': 'date',
    '[object Null]': 'null',
    '[object Array]': 'array',
    '[object Number]': 'number',
    '[object Object]': 'object',
    '[object RegExp]': 'regExp',
    '[object String]': 'string',
    '[object Boolean]': 'boolean',
    '[object Function]': 'function',
    '[object Undefined]': 'undefined',
    '[object HTMLCollection]': 'htmlCollection',
    '[object HTMLDivElement]': 'div',
    '[object HTMLSpanElement]': 'span'
  }
  return map[toString.call(obj)]
}
// 深克隆
export const deepCopyFn = function (res) {
  const t = typeOf(res)
  let o = null
  if (t === 'array') {
    o = []
  } else if (t === 'object') {
    o = {}
  } else {
    return res
  }
  if (t === 'array') {
    for (let i = 0; i < res.length; i++) {
      o.push(deepCopyFn(res[i]))
    }
  } else if ( t === 'object') {
    for (let i in res) {
      o[i] = deepCopyFn(res[i])
    }
  }
  return o
}
// 是否有值
export const hasValueFn = function (val) {
  if (val === '' || val === null || val === undefined || val === 'null' || val === 'undefined') {
    return false
  } else {
    return true
  }
}
// 获取 数组交集, key 有值 则为数组对象[{}]，否则为 非数组对象[''] (建议 arr1 为 短数组, arr2 为长数组, 没有具体测试过不这么传值有何影响，不这么传值应该没啥问题)
export const getArrayIntersectionSetFn = function (arr1 = [], arr2 = [], key = null) {
  let _res = null
  if (hasValueFn(key)) {
    _res = [...arr2].filter(x => [...arr1].some(y => y[key] === x[key]))
  } else {
    const a = new Set(arr1)
    const b = new Set(arr2)
    _res = Array.from(new Set([...b].filter(x => a.has(x))))
  }
  return _res
}
// 获取 数组差集
export const getArrayDifferenceSetFn = function (arr1 = [], arr2 = [], key = null) {
  let _res = null
  if (key) {
    _res = [...arr2].filter(x => [...arr1].every(y => y[key] !== x[key]))
  } else {
    const a = new Set(arr1)
    const b = new Set(arr2)
    _res = Array.from(new Set([...b].filter(x => !a.has(x))))
  }
  return _res
}
// 数组去重, key 有值 则为数组对象[{}]，否则为 非数组对象[''] 
export const getArrayDeduplicationFn = function (arr = [], key = null) {
  let _res = null
  if (hasValueFn(key)) {
    let _newArr = [] // 盛放去重后数据的新数组

    for (const _item1 of arr) {  // 循环j 数组对象的内容
      let flag = true;  // 建立标记，判断数据是否重复，true为不重复
      for (const _item2 of _newArr) {  // 循环新数组的内容
        if (_item1[key] === _item2[key]) { // 让 数组对象的内容与新数组的内容作比较，相同的话，改变标记为false
          flag = false
        }
      }
      if (flag) { //判断是否重复
        _newArr.push(_item1) //不重复的放入新数组。  新数组的内容会继续进行上边的循环。
      }
    }
    _res = _newArr
  } else {
    _res = Array.from(new Set(arr))
  }
  return _res
}
// 获取 dom 属性 值
export const getDomPropertyValueFn = function (dom = null, property = '') {
  if (!dom) {
    return null
  }
  const stylePropertyObj = window.getComputedStyle(dom, null)
  return stylePropertyObj.getPropertyValue(property)
}
export const getRandomRgbColorFn = function (opacity = 1) {
  const r = Math.floor(Math.random() * 256) // 随机生成 256 以内 r 值
  const g = Math.floor(Math.random() * 256) // 随机生成 256 以内 g 值
  const b = Math.floor(Math.random() * 256) // 随机生成 256 以内 b 值
  return `rgb(${r}, ${g}, ${b}, ${opacity})` // 返回rgb(r, g, b, alpha) 格式颜色
}

export const startPlaySuccessAudioFn = function () {
  const _audioDomSuccess = document.querySelector('.audioDomSuccess')
  _audioDomSuccess && _audioDomSuccess.play && _audioDomSuccess.play()
}
export const startPlayErrorAudioFn = function () {
  const _audioDomError = document.querySelector('.audioDomError')
  _audioDomError && _audioDomError.play && _audioDomError.play()
}
export const sleepFn = function (wait = 0) {
  return new Promise(resolve => {
    let _timer = null
    _timer = setTimeout(() => {
      clearTimeout(_timer)
      _timer = null
      resolve(true)
    }, wait);
  })
}
export const getPriceFn = function (price) {
  if (hasValueFn(price)) {
    return (price / 100).toFixed(2)
  } else {
    return ''
  }
}
// 随机字符串(默认 32 位)
export const randomStringFn = function randomStringFn(len = 32) {
  // 默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1
  const charsStr = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
  const maxPos = charsStr.length
  let pwd = ''
  for (let i = 0; i < len; i++) {
    pwd += charsStr.charAt(Math.floor(Math.random() * maxPos))
  }
  return pwd
}
// 仅限数组字符串, 数组对象, 没有覆盖全类型, 页没有做递归比较, 先这么凑活用吧, 艸, 
export const isDifferentFn = function (val1 = 'val1', val2 = 'val2') {
  if (typeOf(val1) === 'array' && typeOf(val2) === 'array') {
    if (val1.length === 0 && val2.length === 0) {
      return true
    } else if (val1.length > 0 && val2.length === 0) {
      return false
    } else if (val2.length > 0 && val1.length === 0) {
      return false
    } else {
      const _val1Copy = deepCopyFn(val1).sort()
      const _val2Copy = deepCopyFn(val2).sort()
      let _arrStr1 = ''
      let _arrStr2 = ''
      _val1Copy.forEach(ele => {
        if (typeOf(val1) === 'object') {
          for (const key in ele) {
            _arrStr1 += `${key}:${ele[key]},`
          }
        } else {
          _arrStr1 += (ele + ',')
        }
      })
      _val2Copy.forEach(ele => {
        if (typeOf(val1) === 'object') {
          for (const key in ele) {
            _arrStr2 += `${key}:${ele[key]},`
          }
        } else {
          _arrStr2 += (ele + ',')
        }
      })
    }
  } else if (typeOf(val1) === 'object' && typeOf(val2) === 'object') {
    if (Object.keys(val1).length === 0 && Object.keys(val2).length === 0) {
      return true
    } else if (Object.keys(val1).length > 0 && Object.keys(val2).length === 0) {
      return false
    } else if (Object.keys(val2).length > 0 && Object.keys(val1).length === 0) {
      return false
    } else {
      let _objStr1 = ''
      let _objStr2 = ''
      for (const key in val1) {
        _objStr1 += `${key}:${val1[key]},`
      }
      for (const key in val2) {
        _objStr2 += `${key}:${_objStr2[key]},`
      }
      return _objStr1 === _objStr2
    }
  } else if (typeOf(val1) === 'string' && typeOf(val2) === 'string') {
    return val1 === val2
  } else {
    return val1 === val2
  }
}

Vue.prototype.winControl = require("@/mode/webControl").default;
Vue.prototype.formatDateTime = formatDateTime;
Vue.prototype.dateStr = dateStr;
Vue.prototype.$echarts = echarts;
Vue.prototype.$typeOf = typeOf;
Vue.prototype.$deepCopyFn = deepCopyFn;
Vue.prototype.$hasValueFn = hasValueFn;
Vue.prototype.$getArrayIntersectionSetFn = getArrayIntersectionSetFn;
Vue.prototype.$getArrayDifferenceSetFn = getArrayDifferenceSetFn;
Vue.prototype.$getArrayDeduplicationFn = getArrayDeduplicationFn;
Vue.prototype.$getDomPropertyValueFn = getDomPropertyValueFn;
Vue.prototype.$getRandomRgbColorFn = getRandomRgbColorFn;
Vue.prototype.$startPlaySuccessAudioFn = startPlaySuccessAudioFn;
Vue.prototype.$startPlayErrorAudioFn = startPlayErrorAudioFn;
Vue.prototype.$sleepFn = sleepFn;
Vue.prototype.$getPriceFn = getPriceFn;
Vue.prototype.$randomStringFn = randomStringFn;
Vue.prototype.$isDifferentFn = isDifferentFn;
Vue.prototype.$_spliceObjToUrlFn = _spliceObjToUrlFn // 将对象转换为 url 的 query 参数 eg: { id: 1, name: '2' } ---> ?id=1&name=2
Vue.prototype.$_isHasValueFn = _isHasValueFn // 是否有值 ['true 有值', 'false 没有值']
Vue.prototype.$_weBtoa = weBtoa
Vue.prototype.$_weAtob = weAtob
