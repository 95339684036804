import request from '@/router/axios'

export function getPage(query) {
  return request({
    // url: 'api/materialgroup/page',
    url: 'admin/sys-file-type/list',
    method: 'get',
    params: query
  }).then(res => {
    return res.data;
  })
}

export function addObj(obj) {
  return request({
    // url: 'api/materialgroup',
    url: 'admin//sys-file-type/save',
    method: 'post',
    data: obj
  }).then(res => {
    return res.data;
  })
}

export function getObj(id) {
  return request({
    url: 'api/materialgroup/' + id,
    method: 'get'
  })
}

export function delObj(id) {
  return request({
    // url: 'api/materialgroup/' + id,
    url: 'admin/sys-file-type/' + id,
    method: 'delete'
  }).then(res => {
    return res.data;
  })
}

export function putObj(obj) {
  return request({
    // url: 'api/materialgroup',
    url:'admin//sys-file-type/edit',
    method: 'put',
    data: obj
  }).then((res) => {
    return res.data;
  })
}
