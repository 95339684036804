var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.value, function (item, index) {
        return [
          _vm.isShowImgList
            ? _c(
                "ul",
                {
                  key: index,
                  staticClass: "el-upload-list el-upload-list--picture-card",
                  class: _vm.value.length < 2 ? "special-single-class" : "",
                },
                [
                  _c(
                    "li",
                    {
                      staticClass: "el-upload-list__item is-ready",
                      class: _vm.value.length < 2 ? "no__margin" : "",
                      style:
                        "width: " +
                        _vm.width +
                        "px;height: " +
                        _vm.height +
                        "px",
                      attrs: { tabindex: "0" },
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { width: "100%", height: "100%" } },
                        [
                          _vm.type == "image"
                            ? _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "viewer",
                                      rawName: "v-viewer",
                                      value: {
                                        movable: false,
                                        url: "data-src",
                                      },
                                      expression:
                                        "{'movable': false, url: 'data-src'}",
                                    },
                                  ],
                                  ref: "materialViewerRef",
                                  refInFor: true,
                                },
                                [
                                  _vm._l(item.split(","), function (imageItem) {
                                    return [
                                      _c("img", {
                                        key: imageItem,
                                        staticClass:
                                          "el-upload-list__item-thumbnail",
                                        attrs: {
                                          src:
                                            imageItem +
                                            "?x-oss-process=image/resize,m_fixed,h_100,w_100",
                                          "data-src": imageItem,
                                        },
                                      }),
                                    ]
                                  }),
                                ],
                                2
                              )
                            : _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "viewer",
                                      rawName: "v-viewer",
                                      value: { movable: true, url: "data-src" },
                                      expression:
                                        "{'movable': true, url: 'data-src'}",
                                    },
                                  ],
                                  ref: "materialViewerRef",
                                  refInFor: true,
                                },
                                [
                                  _vm._l(item.split(","), function (imageItem) {
                                    return [
                                      _c(
                                        "video",
                                        {
                                          key: imageItem,
                                          attrs: {
                                            width: _vm.width,
                                            height: _vm.height,
                                            controls: "controls",
                                            preload: "auto",
                                          },
                                        },
                                        [
                                          _c("source", {
                                            attrs: {
                                              "data-src": imageItem,
                                              src: imageItem,
                                              type: "video/mp4",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  }),
                                ],
                                2
                              ),
                          _c(
                            "div",
                            {
                              staticClass: "el-upload-list__item-actions",
                              staticStyle: { "font-size": "12px" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "e-upload-list__item-actions__total-wrap",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "el-upload-list__item-icon-wrap",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "el-upload-list__item-preview",
                                          on: {
                                            click: function ($event) {
                                              return _vm.zoomMaterial(index)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-view",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "el-upload-list__item-delete",
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteMaterial(index)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-delete",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "el-upload-list__item-icon-wrap",
                                    },
                                    [
                                      index != 0
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "el-upload-list__item-preview",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.moveMaterial(
                                                    index,
                                                    "up"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-back",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      index != _vm.value.length - 1
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "el-upload-list__item-preview",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.moveMaterial(
                                                    index,
                                                    "down"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-right",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ]
      }),
      _vm.num > _vm.value.length && _vm.isShowImgList
        ? _c(
            "div",
            {
              staticClass: "el-upload el-upload--picture-card",
              class: { disabledUpLoad: _vm.disabled },
              style:
                "width: " +
                _vm.width +
                "px;height: " +
                _vm.height +
                "px;" +
                "line-height:" +
                _vm.height +
                "px;",
              attrs: { tabindex: "0", disabled: _vm.disabled },
              on: { click: _vm.toSeleteMaterial },
            },
            [_c("i", { staticClass: "el-icon-plus" })]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          staticStyle: { "text-align": "center" },
          attrs: {
            "append-to-body": "",
            visible: _vm.dialogVisible,
            width: "35%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.type == "image"
            ? _c("img", {
                staticStyle: { width: "100%" },
                attrs: { src: _vm.url, alt: "" },
              })
            : _vm._e(),
          _c(
            "video",
            {
              staticStyle: { "max-height": "500px" },
              attrs: {
                width: "100%",
                controls: "controls",
                preload: "auto",
                autoplay: "autoplay",
              },
            },
            [
              _c("source", {
                attrs: {
                  "data-src": _vm.url,
                  src: _vm.url,
                  type: "video/mp4",
                  media: "screen and (min-width:320px)",
                },
              }),
            ]
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "my-dialog",
          attrs: {
            title: "图片素材库",
            "append-to-body": "",
            visible: _vm.listDialogVisible,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.listDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-container",
            { staticStyle: { height: "80vh" } },
            [
              _c(
                "el-aside",
                {
                  staticClass: "myAside",
                  staticStyle: { "border-right": "2px #f0f2f4 solid" },
                  attrs: { width: "15%" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "material-left-addbtn",
                      staticStyle: { "margin-bottom": "10px" },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "el-icon-plus",
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.materialgroupAdd()
                            },
                          },
                        },
                        [_vm._v(" 添加分组 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "el-icon-search",
                          attrs: { size: "mini" },
                          on: { click: _vm.materialgroupSearch },
                        },
                        [_vm._v(" 分组查询 ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-tabs",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.materialgroupLoading,
                          expression: "materialgroupLoading",
                        },
                      ],
                      staticClass: "material-left-tabs",
                      attrs: { "tab-position": "left" },
                      on: { "tab-click": _vm.tabClick },
                      model: {
                        value: _vm.materialgroupObjId,
                        callback: function ($$v) {
                          _vm.materialgroupObjId = $$v
                        },
                        expression: "materialgroupObjId",
                      },
                    },
                    _vm._l(_vm.materialgroupList, function (item, index) {
                      return _c(
                        "el-tab-pane",
                        { key: index, attrs: { name: String(item.id) } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "materialGroupLab",
                              attrs: { slot: "label" },
                              slot: "label",
                            },
                            [
                              _c("span", {
                                staticClass: "delete-edit-icon el-icon-edit",
                                on: {
                                  click: function ($event) {
                                    return _vm.materialgroupEdit(
                                      _vm.materialgroupObj
                                    )
                                  },
                                },
                              }),
                              _c("span", [_vm._v(_vm._s(item.fileTypeName))]),
                              _c("span", {
                                staticClass: "delete-edit-icon el-icon-delete",
                                on: {
                                  click: function ($event) {
                                    return _vm.materialgroupDelete(
                                      _vm.materialgroupObj
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-main",
                [
                  _c("el-card", { staticClass: "my-card" }, [
                    _c(
                      "div",
                      { attrs: { slot: "header" }, slot: "header" },
                      [
                        _c(
                          "el-row",
                          { staticClass: "search-add-bar" },
                          [
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c(
                                "div",
                                { staticClass: "material-search" },
                                [
                                  _c(
                                    "el-form",
                                    {
                                      ref: "materialSearch",
                                      staticClass: "search-form-part",
                                      attrs: { model: _vm.materialSearchForm },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入图片名称",
                                            },
                                            model: {
                                              value:
                                                _vm.materialSearchForm
                                                  .imageName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.materialSearchForm,
                                                  "imageName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "materialSearchForm.imageName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "search-btn-part" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.searchPicture.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("搜索")]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.resetImageSearch.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("重置")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "text-align": "right" },
                                attrs: { span: 12 },
                              },
                              [
                                _c(
                                  "el-upload",
                                  {
                                    attrs: {
                                      action: "",
                                      "list-type": "picture",
                                      multiple: true,
                                      "show-file-list": false,
                                      "http-request": _vm.materialUpload,
                                      "before-upload": _vm.beforeUploadFn,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          type: "primary",
                                        },
                                      },
                                      [_vm._v("点击上传")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.tableLoading,
                            expression: "tableLoading",
                          },
                        ],
                      },
                      [
                        _vm.tableData.length <= 0
                          ? _c("el-alert", {
                              attrs: {
                                title: "暂无数据",
                                type: "info",
                                closable: false,
                                center: "",
                                "show-icon": "",
                              },
                            })
                          : _vm._e(),
                        _c(
                          "el-row",
                          { attrs: { gutter: 5 } },
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                attrs: { max: _vm.num - _vm.value.length },
                                model: {
                                  value: _vm.urls,
                                  callback: function ($$v) {
                                    _vm.urls = $$v
                                  },
                                  expression: "urls",
                                },
                              },
                              _vm._l(_vm.tableData, function (item, index) {
                                return _c(
                                  "el-col",
                                  {
                                    key: index,
                                    staticClass: "material-select-card",
                                    staticStyle: { width: "18.8%" },
                                    attrs: { span: 4 },
                                  },
                                  [
                                    _c(
                                      "el-card",
                                      {
                                        attrs: {
                                          "body-style": { padding: "5px" },
                                        },
                                      },
                                      [
                                        _vm._v(" " + _vm._s(item.type) + " "),
                                        item.type.includes("video")
                                          ? _c("video", {
                                              attrs: {
                                                width: "100%",
                                                height: "100",
                                                controls: "controls",
                                                preload: "auto",
                                                src: item.fileName,
                                              },
                                            })
                                          : _c("el-image", {
                                              staticStyle: {
                                                width: "100%",
                                                height: "100px",
                                              },
                                              attrs: {
                                                src: item.fileName,
                                                fit: "contain",
                                                "preview-src-list": [
                                                  item.fileName,
                                                ],
                                              },
                                            }),
                                        _vm._v(
                                          " " + _vm._s(item.fileName) + " "
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "material-image-name",
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(item.original)),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "material-operate" },
                                          [
                                            _c(
                                              "el-checkbox",
                                              {
                                                staticClass:
                                                  "material-checkbox",
                                                attrs: { label: item.fileName },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "12px",
                                                    },
                                                  },
                                                  [_vm._v("选择")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticClass: "col-do",
                                                    attrs: { span: 24 },
                                                  },
                                                  [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          type: "text",
                                                          size: "mini",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.materialDel(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("删除")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c("el-pagination", {
                          staticClass: "pagination",
                          attrs: {
                            "current-page": _vm.page.currentPage,
                            "page-sizes": [12, 24],
                            "page-size": _vm.page.pageSize,
                            layout: "total, sizes, prev, pager, next, jumper",
                            total: _vm.page.total,
                          },
                          on: {
                            "update:currentPage": function ($event) {
                              return _vm.$set(_vm.page, "currentPage", $event)
                            },
                            "update:current-page": function ($event) {
                              return _vm.$set(_vm.page, "currentPage", $event)
                            },
                            "size-change": _vm.sizeChange,
                            "current-change": _vm.pageChange,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.listDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.urls && _vm.urls.length > 0,
                      expression: "urls && urls.length > 0",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.sureUrls },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            width: "40%",
            title: _vm.groupTextMap[_vm.groupDialogStatus] + "分组",
            visible: _vm.groupDialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.groupDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "cateForm",
              staticClass: "group-product-form",
              attrs: {
                model: _vm.newCategoryForm,
                rules: _vm.categoryRule,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分组名称", prop: "fileTypeName" } },
                [
                  _c("el-input", {
                    attrs: {
                      "auto-complete": "off",
                      placeholder: "请设置分组名称",
                      maxlength: "10",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.newCategoryForm.fileTypeName,
                      callback: function ($$v) {
                        _vm.$set(_vm.newCategoryForm, "fileTypeName", $$v)
                      },
                      expression: "newCategoryForm.fileTypeName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      "auto-complete": "off",
                      placeholder: "请添加备注",
                    },
                    model: {
                      value: _vm.newCategoryForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.newCategoryForm, "remark", $$v)
                      },
                      expression: "newCategoryForm.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancelCateDialog } }, [
                _vm._v("取消"),
              ]),
              _vm.groupDialogStatus === "create"
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.sendingRequest,
                          expression: "sendingRequest",
                        },
                      ],
                      staticClass: "form-submit-btn",
                      attrs: { type: "primary" },
                      on: { click: _vm.submitAddCate },
                    },
                    [_vm._v("确定")]
                  )
                : _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.sendingRequest,
                          expression: "sendingRequest",
                        },
                      ],
                      staticClass: "form-submit-btn",
                      attrs: { type: "primary" },
                      on: { click: _vm.submitEditCate },
                    },
                    [_vm._v("确定")]
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            width: "40%",
            title: "分组查询",
            visible: _vm.seachDialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.seachDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "seachForm",
              staticClass: "group-product-form",
              attrs: {
                model: _vm.seachCategoryForm,
                rules: _vm.seachRule,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分组名称", prop: "fileTypeName" } },
                [
                  _c("el-input", {
                    attrs: {
                      "auto-complete": "off",
                      placeholder: "请输入需要查询的分组名称",
                      maxlength: "10",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.seachCategoryForm.fileTypeName,
                      callback: function ($$v) {
                        _vm.$set(_vm.seachCategoryForm, "fileTypeName", $$v)
                      },
                      expression: "seachCategoryForm.fileTypeName",
                    },
                  }),
                  _c("p", { staticStyle: { color: "red" } }, [
                    _vm._v(
                      "如需查询全部分组,无需输入分组名称,直接点击确定即可"
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancelSeachDialog } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.sendingRequest,
                      expression: "sendingRequest",
                    },
                  ],
                  staticClass: "form-submit-btn",
                  attrs: { type: "primary" },
                  on: { click: _vm.submitSeach },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.isShowOssProgress,
            "append-to-body": "",
            width: "500px",
            "show-close": false,
            modal: true,
            title: "文件上传中,请勿操作页面",
            center: "",
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "text-align": "center" } },
            [
              _c("el-progress", {
                attrs: { type: "circle", percentage: _vm.OssProgress },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }