var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "avue-top" }, [
    _c("div", { staticClass: "top-bar__left" }, [
      _vm.showCollapse
        ? _c(
            "div",
            {
              staticClass: "avue-breadcrumb",
              class: [{ "avue-breadcrumb--active": _vm.isCollapse }],
            },
            [
              _c("i", {
                staticClass: "icon-navicon",
                on: { click: _vm.setCollapse },
              }),
            ]
          )
        : _vm._e(),
    ]),
    _c("div", { staticClass: "top-bar__title" }, [
      _vm.showMenu
        ? _c(
            "div",
            { staticClass: "top-bar__item top-bar__item--show" },
            [_c("top-menu")],
            1
          )
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "top-bar__right" },
      [
        _vm.showColor
          ? _c(
              "el-tooltip",
              {
                staticStyle: { display: "none" },
                attrs: {
                  effect: "dark",
                  content: "主题色",
                  placement: "bottom",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "top-bar__item" },
                  [_c("top-color")],
                  1
                ),
              ]
            )
          : _vm._e(),
        _vm.showLock
          ? _c(
              "el-tooltip",
              {
                attrs: { effect: "dark", content: "锁屏", placement: "bottom" },
              },
              [_c("div", { staticClass: "top-bar__item" }, [_c("top-lock")], 1)]
            )
          : _vm._e(),
        _vm.showTheme
          ? _c(
              "el-tooltip",
              {
                staticStyle: { display: "none" },
                attrs: {
                  effect: "dark",
                  content: "特色主题",
                  placement: "bottom",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "top-bar__item" },
                  [_c("top-theme")],
                  1
                ),
              ]
            )
          : _vm._e(),
        _vm.showFullScren
          ? _c(
              "el-tooltip",
              {
                attrs: {
                  content: _vm.isFullScren ? "退出全屏" : "全屏",
                  effect: "dark",
                  placement: "bottom",
                },
              },
              [
                _c("div", { staticClass: "top-bar__item" }, [
                  _c("i", {
                    class: _vm.isFullScren
                      ? "icon-zuixiaohua"
                      : "icon-quanpingzuidahua",
                    on: { click: _vm.handleScreen },
                  }),
                ]),
              ]
            )
          : _vm._e(),
        _vm.userInfo.avatar
          ? _c(
              "el-tooltip",
              {
                attrs: {
                  effect: "dark",
                  content: "用户头像",
                  placement: "bottom",
                },
              },
              [
                _c("img", {
                  staticClass: "top-bar__img",
                  attrs: { src: _vm.userInfo.avatar },
                }),
              ]
            )
          : _vm._e(),
        _c(
          "el-dropdown",
          { attrs: { trigger: "click" } },
          [
            _c("span", { staticClass: "el-dropdown-link" }, [
              _vm._v(" " + _vm._s(_vm.userInfo.username) + " "),
              _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
            ]),
            _c(
              "el-dropdown-menu",
              { attrs: { slot: "dropdown" }, slot: "dropdown" },
              [
                _c(
                  "el-dropdown-item",
                  { attrs: { divided: "" } },
                  [
                    _c("router-link", { attrs: { tag: "div", to: "/" } }, [
                      _vm._v(" 首页 "),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-dropdown-item",
                  { attrs: { divided: "" } },
                  [
                    _c(
                      "router-link",
                      { attrs: { tag: "div", to: "/info/index" } },
                      [_vm._v(" 个人信息 ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-dropdown-item",
                  {
                    attrs: { divided: "" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.logout.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("退出系统 ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("top-setting", { ref: "seting" }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }