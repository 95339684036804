import Layout from '@/page/index/'

export default [
  {
    path: '/testPage',
    component: Layout,
    children: [
      { path: 'testPage', name: '测试页面', component: () => import(/* webpackChunkName: "page" */ '@/views/testPage/testPage.vue') }
    ]
  },
  {
    path: '/info',
    component: Layout,
    redirect: '/info/index',
    children: [
      { path: 'index', name: '个人信息', component: () => import(/* webpackChunkName: "page" */ '@/views/admin/user/info') }
    ]
  },
  {
    path: '/admin',
    component: Layout,
    name: 'admin',
    meta: {title: '权限管理',icon: 'icon-quanxianguanli'},
    children: [
      { path:'role/roleDetail', name: '角色详情', component: () => import('@/views/admin/newRole/roleDetail'), meta: { title: '角色详情', icon: 'icon-quanxianguanli' }, hidden: true, }
    ]
  },
  {
    path: '/pms',
    component: Layout,
    redirect: '/pms/product',
    name: 'pms',
    meta: { title: '产品管理', icon: 'product' },
    children: [
      { path: 'addProduct', name: '新增产品', component: () => import('@/views/pms/product/add'), meta: { title: '新增产品', icon: 'product-add' }, hidden: true },
      { path: 'updateProduct', name: '修改产品', component: () => import('@/views/pms/product/update'), meta: { title: '修改产品', icon: 'product-add' }, hidden: true },
      { path: 'oneProductComment', name: '产品评价', component: () => import('@/views/pms/comment/productComment'), meta: { title: '产品评价' }, hidden: true }
    ]
  },
  {
    path: '/oms',
    component: Layout,
    redirect: '/oms/order',
    name: 'oms',
    meta: { title: '订单管理', icon: 'order' },
    children: [
      { path: 'orderDetail', name: '订单详情', component: () => import('@/views/oms/order/orderDetail'), meta: { title: '订单详情' }, hidden:true },
      { path: 'orderTrail', name: '订单轨迹', component: () => import('@/views/oms/order/orderTrail'), meta: { title: '订单轨迹' }, hidden:true },
      { path: 'afterSaleDetailProd', name: '退货详情', component: () => import('@/views/oms/aftersale/afterSaleDetailProd'), meta: { title: '退货详情' }, hidden:true },
      { path: 'afterSaleDetailMoney', name: '退款详情', component: () => import('@/views/oms/aftersale/afterSaleDetailMoney'), meta: { title: '退款详情' }, hidden:true },
      { path: 'deliverOrderList', name: '发货列表', component: () => import('@/views/oms/order/deliverOrderList'), meta: { title: '发货列表' }, hidden:true }
    ]
  },
  {
    path:'/sms',
    component: Layout,
    redirect: '/sms/coupon',
    name: 'sms',
    meta: { title: '营销管理', icon: 'sms'},
    children: [
      { path: 'addCoupon', name: '添加优惠券', component: () => import('@/views/sms/coupon/add'), meta: { title: '添加优惠券' }, hidden: true },
      { path: 'updateCoupon', name: '修改优惠券', component: () => import('@/views/sms/coupon/update'), meta: { title: '修改优惠券' }, hidden: true },
      { path: 'couponHistory', name: '优惠券领取详情', component: () => import('@/views/sms/coupon/history'), meta: { title: '优惠券领取详情' }, hidden: true },
      { path: 'groupActivityDetail', name: '团购详情', component: () => import('@/views/sms/groupActivity/groupList/detail'), meta: { title: '团购详情' }, hidden: true },
      { path: 'oneYuanDetail', name: '代付详情', component: () => import('@/views/sms/oneYuan/oneYuanDetail'), meta: { title: '代付详情' }, hidden: true, },
      { path: 'discountsProdDetail', name: '约惠商品详情', component: () => import('@/views/sms/discounts/product/prodDetail'), meta: { title: '约惠商品详情' }, hidden: true },    
      { path: 'redPackageDetailList', name: '红包明细', component: () => import('@/views/sms/redPackets/redPackageDetailList'), meta: { title: '红包明细' }, hidden: true },
      { path: 'usersCouponDetailList', name: '优惠券明细', component: () => import('@/views/sms/usersCoupon/usersCouponDetailList'), meta: { title: '优惠券明细' }, hidden: true },
      { path: 'usersDetail', name: '会员详情', component: () => import('@/views/sms/usersDetail/index'), meta: { title: '会员详情' }, hidden:true         },
      { path: 'marketingActivity/detail/:id/:platformCode/:isOnSale', name: '活动-商品', component: () => import('@/views/sms/marketingActivity/detail/index.vue'), meta: { title: '活动-商品', keepAlive: true }, hidden: true },
      { path: 'marketingActivity/setContent/:id/:platformCode', name: '活动-配置内容', component: () => import('@/views/sms/marketingActivity/setContent/index.vue'), meta: { title: '活动-配置内容' }, hidden: true },
      { path: 'convertZone/setting/:id/:platformCode/:isOnSale', name: '兑换-设置', component: () => import('@/views/sms/convertZone/setting/index.vue'), meta: { title: '兑换-设置' }, hidden: true },
    ]    
  },
  {
    path: '/ums',
    component: Layout,
    name: 'ums',
    children: [
      { path: 'group/setting/index/:id', name: '查看分组', component: () => import('@/views/ums/group/setting/index.vue'), meta: { title: '查看分组' }, hidden: true },
    ]
  },
  {
    path: '/miniPage',
    component: Layout,
    name: 'miniPage',
    children: [
      { path: 'add', name: '微页面新增', component: () => import('@/views/miniPage/addEdit/index.vue'), meta: { title: '微页面新增' }, hidden: true },
      { path: 'edit/:id/:encodeStr', name: '微页面编辑', component: () => import('@/views/miniPage/addEdit/index.vue'), meta: { title: '微页面编辑' }, hidden: true },
    ]
  },
  {
    path: '/sms/wepages',
    component: Layout,
    name: 'wepages',
    children: [
      { path: 'edit', name: '编辑页面', component: () => import('@/views/sms/wepages/edit.vue'), meta: { title: '编辑页面' }, hidden: true },
    ]
  },
  {
    path: '/course',
    component: Layout,
    name: 'course',
    children: [
      { path: 'column/catalog', name: '目录管理', component: () => import('@/views/course/column/catalog.vue'), meta: { title: '目录管理' }, hidden: true },
      { path: 'column/add', name: '新增专栏', component: () => import('@/views/course/column/add.vue'), meta: { title: '新增专栏' }, hidden: true },
      { path: 'column/edit', name: '编辑专栏', component: () => import('@/views/course/column/edit.vue'), meta: { title: '编辑专栏' }, hidden: true },
      { path: 'single/add', name: '新增课程', component: () => import('@/views/course/single/add.vue'), meta: { title: '新增课程' }, hidden: true },
      { path: 'single/edit', name: '编辑课程', component: () => import('@/views/course/single/edit.vue'), meta: { title: '编辑课程' }, hidden: true },
    ],
    
  }
]
