import { getAssumeRole, uploadSaveData } from '@/api/tools/material'
const OSS = require('ali-oss');

export default {
  data() {
    return {
      OssData: {},
      OssClient: null,
      OssProgress:0,
      fileSize:0
    }
  },
  computed:{
    isShowOssProgress(){
      return this.fileSize / 1024 / 1024 > 1
    }
  },
  methods: {
    // 每次上传的时候，调用一次获取密钥接口，生成ossClient
    setUpClient() {
      return new Promise((resolve, reject) => {
        getAssumeRole().then(res => {
          const data = res.data.data
          this.OssData = data;
          this.OssClient = new OSS({
            // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
            accessKeyId: data.accessKeyId,
            accessKeySecret: data.accessKeySecret,
            // 从STS服务获取的安全令牌（SecurityToken）。
            stsToken: data.securityToken,
            // 填写Bucket所在地域。以华东1（杭州）为例，设置region为oss-cn-hangzhou。
            region: data.region,
            // region: 'oss-cn-shenzhen',
            // 填写Bucket名称，例如examplebucket。
            bucket: data.bucket,
            secure: true,
          });
          resolve(200)
        }).catch(err => {
          reject(err)
        })
      })
    },
    // ossClient 上传文件
   async ossPutObject(file) {
      // const options = {
      //   mime: "json",
      //   headers: { "Content-Type": "text/plain" },
      // };
      const fileName = this.OssData.path + '/' + file.name
      this.OssProgress=0
      this.fileSize =file.size
      //   return false
      return new Promise((resolve, reject) => {
        this.OssClient.multipartUpload(fileName, file, 
          {
            headers: { "Content-Type": "text/plain" },
            mime: "json",
            progress: (p, cpt, res) => {
              // 为了实现断点上传，您可以在上传过程中保存断点信息（checkpoint）。发生上传错误后，将已保存的checkpoint作为参数传递给multipartUpload，此时将从上次上传失败的地方继续上传。
              this.abortCheckpoint = cpt;
              // 获取上传进度。
              this.OssProgress =parseInt( Number(p*100).toFixed(0))
            },
          }
        )
        .then(res => {
          setTimeout(()=>{
            this.OssProgress = 0
            this.fileSize = 0
          },500)
          const url = 'http://'+res.bucket+'.'+this.OssData.region+ '.aliyuncs.com/' + res.name
          resolve(url)
        }).catch(err => {
          this.OssProgress = 0
          this.fileSize = 0
          reject(err)
        })
      })
    },
    // ossClient 上传文件后，调用保存文件接口，把信息保存到系统数据中
    saveSysFile(fd) {
      return new Promise((resole, reject) => {
        uploadSaveData(fd).then(res => {
          resole(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    uploadFail(){
      this.$message.error('上传文件失败')
    }
  },
}