<template>
  <div>
    <template v-for="(item, index) in value">
      <ul class="el-upload-list el-upload-list--picture-card"
        :key="index"
        :class="value.length < 2 ? 'special-single-class' : ''"
        v-if="isShowImgList"
      >
        <li tabindex="0" class="el-upload-list__item is-ready" :class="value.length < 2 ? 'no__margin' : ''" :style="'width: '+width+'px;height: '+height +'px'"> <!-- :style="{width: width + 'px', height:height + 'px'}" -->
          <div style="width: 100%; height: 100%">
            <!-- <img :src="item" alt="" class="el-upload-list__item-thumbnail"> -->
            
            <!--图片显示--->
            <div ref="materialViewerRef" v-viewer="{'movable': false, url: 'data-src'}" v-if="type == 'image'">
              <template v-for="imageItem in item.split(',')">
                  <img
                  class="el-upload-list__item-thumbnail"
                  :src="imageItem + '?x-oss-process=image/resize,m_fixed,h_100,w_100'"
                  :data-src="imageItem"
                  :key="imageItem">
              </template>
            </div>
            <!--图片显示--->

            <!--视频显示--->
            <div ref="materialViewerRef" v-viewer="{'movable': true, url: 'data-src'}" v-else>
              <template v-for="imageItem in item.split(',')">
                
                  <video :width="width" :height="height" controls="controls" preload="auto" :key="imageItem" >
                      <source :data-src="imageItem" :src="imageItem"   type="video/mp4">
                  </video>
              </template>
            </div>
            <!--视频显示--->

            <div class="el-upload-list__item-actions" style="font-size: 12px;" >
                <div class="e-upload-list__item-actions__total-wrap">
                  <div class="el-upload-list__item-icon-wrap">
                      <span class="el-upload-list__item-preview" @click="zoomMaterial(index)">
                        <i class="el-icon-view" />
                      </span>
                      <span class="el-upload-list__item-delete" @click="deleteMaterial(index)">
                        <i class="el-icon-delete" />
                      </span>
                  </div>
                  <div class="el-upload-list__item-icon-wrap">
                      <span v-if="index != 0" class="el-upload-list__item-preview" @click="moveMaterial(index,'up')">
                        <i class="el-icon-back" />
                      </span>
                      <span v-if="index != value.length-1" class="el-upload-list__item-preview" @click="moveMaterial(index,'down')">
                        <i class="el-icon-right" />
                      </span>
                  </div>
                </div>
            </div>
          </div>
        </li>
      </ul>
    </template>
    <div class="el-upload el-upload--picture-card" tabindex="0"
      :class="{ 'disabledUpLoad': disabled }"
      :style="'width: ' + width + 'px;height: ' + height + 'px;' + 'line-height:' + height + 'px;'"
      :disabled="disabled"
      v-if="num > value.length && isShowImgList"
      @click="toSeleteMaterial"
    >
      <i class="el-icon-plus" />
    </div>

    <el-dialog
      append-to-body
      :visible.sync="dialogVisible"
      width="35%"
      style="text-align: center;"
    >
      <img :src="url" alt="" style="width: 100%" v-if="type == 'image'">
      <video width="100%" style="max-height: 500px;" controls="controls" preload="auto" autoplay="autoplay">
        <source :data-src="url" :src="url"  type="video/mp4"  media="screen and (min-width:320px)">
    </video>
    </el-dialog>

    <el-dialog
      class="my-dialog"
      title="图片素材库"
      append-to-body
      :visible.sync="listDialogVisible"
      width="70%"
    >
      <el-container style="height: 80vh">
        <el-aside width="15%" class="myAside" style="border-right:2px #f0f2f4 solid">
          <div class="material-left-addbtn" style="margin-bottom: 10px">
            <el-button class="el-icon-plus" size="mini" @click="materialgroupAdd()">
              添加分组
            </el-button>
              <el-button class="el-icon-search" size="mini" @click="materialgroupSearch">
                分组查询
            </el-button>
          </div>
          <el-tabs class="material-left-tabs" v-model="materialgroupObjId" v-loading="materialgroupLoading" tab-position="left" @tab-click="tabClick">
            <el-tab-pane
              v-for="(item,index) in materialgroupList"
              :key="index"
              :name="String(item.id)"
            >
              <div class="materialGroupLab" slot="label">
                  <span class="delete-edit-icon el-icon-edit" @click="materialgroupEdit(materialgroupObj)"></span>
                  <span>{{ item.fileTypeName }}</span>
                  <span class="delete-edit-icon el-icon-delete" @click="materialgroupDelete(materialgroupObj)"></span>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-aside>
        <el-main>
          <el-card class="my-card">
            <div slot="header">
              <el-row class="search-add-bar">
                <el-col :span="12">
                  <!-- <span>{{ materialgroupObj.name }}</span>
                  <span v-if="materialgroupObj.id != '-1'">
                    <el-button size="small" type="text" class="el-icon-edit" style="margin-left: 10px;" @click="materialgroupEdit(materialgroupObj)">重命名</el-button>
                    <el-button size="small" type="text" class="el-icon-delete" style="margin-left: 10px;color: red" @click="materialgroupDelete(materialgroupObj)">删除</el-button>
                  </span> -->
                    <div class="material-search">
                        <el-form class="search-form-part" ref="materialSearch" :model="materialSearchForm">
                            <el-form-item>
                                <el-input v-model="materialSearchForm.imageName" placeholder="请输入图片名称"></el-input>
                            </el-form-item>
                        </el-form>
                        <div class="search-btn-part">
                            <el-button size="mini" type="primary" @click.stop="searchPicture">搜索</el-button>
                            <el-button size="mini" type="primary" @click.stop="resetImageSearch">重置</el-button>
                        </div>
                    </div>
                </el-col>
                <el-col :span="12" style="text-align: right;">
                  <!-- 源代码自带上传组件 -->
                  <!-- <el-upload
                    :action="elUploadObject.useOss?elUploadObject.ossUploadUrl:elUploadObject.minioUploadUrl"
                    :data="elUploadObject.useOss?elUploadObject.dataObj:null"
                    list-type="picture"
                    :multiple="false"
                    :file-list="[]"
                    :on-progress="handleProgress"
                    :before-upload="beforeUpload"
                    :on-success="handleSuccess"
                  > -->
                  <!-- 自定义方法的上传组件 -->
                  <el-upload
                    :action="''"
                    list-type="picture"
                    :multiple="true"
                    :show-file-list="false"
                    :http-request="materialUpload"
                    :before-upload="beforeUploadFn"
                  >
                    <el-button size="mini" type="primary">点击上传</el-button>
                  </el-upload>
                </el-col>
              </el-row>
            </div>
            <!-- {{ isShowOssProgress }} -->
            <div v-loading="tableLoading">
              <!-- <div class="progress-container" v-if="isShowProgress">
                  <el-progress type="circle" :percentage="progressPercent"></el-progress>
                  <span class="progress-msg">{{ progressStatusMsg }}</span>
              </div> -->
              <el-alert
                v-if="tableData.length <= 0"
                title="暂无数据"
                type="info"
                :closable="false"
                center
                show-icon
              />
              <el-row :gutter="5">
                <el-checkbox-group v-model="urls" :max="num - value.length"> <!-- 多图MAX判断方法  :max="num - value.length" -->
                  <el-col class="material-select-card" v-for="(item,index) in tableData" :key="index" :span="4" style="width: 18.8%;">
                    <el-card :body-style="{ padding: '5px' }">
                      {{ item.type }}
                      <video width="100%" height="100" controls="controls" preload="auto" v-if="item.type.includes('video')" :src="item.fileName">
                            <!-- <source :src="item.fileName"   type="video/mp4"> -->
                        </video>

                      <el-image
                        v-else
                        style="width: 100%;height: 100px"
                        :src="item.fileName"
                        fit="contain"
                        :preview-src-list="[item.fileName]"
                      />
                      {{ item.fileName }}

                      <div class="material-image-name">
                          <span>{{item.original}}</span>
                      </div>
                      <div class="material-operate">
                        <el-checkbox class="material-checkbox" :label="item.fileName">
                          <span style="font-size: 12px;">选择</span>
                        </el-checkbox>
                        <el-row>
                          <el-col :span="24" class="col-do">
                            <el-button type="text" size="mini" @click="materialDel(item)">删除</el-button>
                          </el-col>
                        </el-row>

                      </div>
                    </el-card>
                  </el-col>
                </el-checkbox-group>
              </el-row>
              <el-pagination
                :current-page.sync="page.currentPage"
                :page-sizes="[12, 24]"
                :page-size="page.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="page.total"
                class="pagination"
                @size-change="sizeChange"
                @current-change="pageChange"
              />
            </div>
          </el-card>
        </el-main>
      </el-container>
      <span slot="footer" class="dialog-footer">
        <el-button @click="listDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureUrls" v-show="urls && urls.length > 0">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 创建/编辑分组用的表单 -->
    <el-dialog
    append-to-body
    width="40%"
    :title="groupTextMap[groupDialogStatus] + '分组'"
    :visible.sync="groupDialogVisible">
        <el-form ref="cateForm" class="group-product-form" :model="newCategoryForm" :rules="categoryRule" label-width="80px">
            <el-form-item label="分组名称" prop="fileTypeName">
                <el-input v-model="newCategoryForm.fileTypeName" auto-complete="off" placeholder="请设置分组名称" maxlength="10" show-word-limit />
            </el-form-item>
            <el-form-item label="备注">
                <el-input  v-model="newCategoryForm.remark" type="textarea" auto-complete="off" placeholder="请添加备注" />
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="cancelCateDialog">取消</el-button>
            <el-button type="primary"  v-loading="sendingRequest" class="form-submit-btn" v-if="groupDialogStatus === 'create'" @click="submitAddCate">确定</el-button>
            <el-button type="primary" v-loading="sendingRequest" class="form-submit-btn" v-else @click="submitEditCate">确定</el-button>
        </div>
    </el-dialog>
    <el-dialog
    append-to-body
    width="40%"
    :title="'分组查询'"
    :visible.sync="seachDialogVisible">
        <el-form ref="seachForm" class="group-product-form" :model="seachCategoryForm" :rules="seachRule" label-width="80px">
            <el-form-item label="分组名称" prop="fileTypeName">
                <el-input v-model="seachCategoryForm.fileTypeName" auto-complete="off" placeholder="请输入需要查询的分组名称" maxlength="10" show-word-limit />
                <p style="color: red">如需查询全部分组,无需输入分组名称,直接点击确定即可</p>
            </el-form-item>
            
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="cancelSeachDialog">取消</el-button>
            <el-button type="primary" v-loading="sendingRequest" class="form-submit-btn" @click="submitSeach">确定</el-button>
        </div>
    </el-dialog>
    <el-dialog 
      :visible="isShowOssProgress"
      append-to-body
      width="500px"
      :show-close="false"
      :modal="true"
      title="文件上传中,请勿操作页面"
      center
    >
      <div style="text-align: center;">
        <el-progress type="circle" :percentage="OssProgress" />
      </div>
    </el-dialog>
  </div>

</template>

<script>
import { getPage as materialgroupPage, addObj as materialgroupAdd, delObj as materialgroupDel, putObj as materialgroupEdit } from '@/api/tools/materialgroup'
import { getPage, addObj, delObj, putObj, uploadMaterialImage } from '@/api/tools/material'
import { validateRequireContent } from '@/util/validate'
import {policy} from '@/api/oss'
import { getStore } from '@/util/store'
import { mapGetters } from 'vuex'
import ossMixin from "@/mixins/oss.js"
const defaultCateForm = {
  fileTypeName: "",
  remark: "",
}
export default {
  name: 'MaterialList',
  mixins:[ossMixin],
  props: {
    // 素材数据
    value: { type: Array, default: ()=> ([]) },
    // 素材类型
    type: { type: String },
    // 素材限制数量，默认5个
    num: { type: Number, default: 1 },
    // 宽度
    width: { type: Number, default: 150 },
    // 宽度
    height: { type: Number, default: 150 },
    disabled: { type: Boolean, default: false },
    isShowImgList: { type: Boolean, default: true, },
    isRechText: { type: Boolean, default: false, },
    limitSize: { type: Number, default: 512000 }, // 单位 kb
  },
  data() {
    const validateFileTypeName = (rule,value,callback) => {
      if(validateRequireContent(value)){
        callback();
      }else{
        callback(new Error('请输入正确的分组名称'));
      }
    }
    return {
      headers: {
        Authorization: getStore({ name: 'access_token'}) || '',
      },
      dialogVisible: false,
      url: '',
      listDialogVisible: false,
      sendingRequest: false,
      materialgroupList: [],
      materialgroupObjId: '',
      materialgroupObj: {},
      materialgroupLoading: false,
      tableData: [],
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条
        ascs: [], // 升序字段
        descs: 'create_time'// 降序字段
      },
      tableLoading: false,
      groupId: null,
      urls: [],
      materialSearchForm: {
          imageName: null,
      },
      //上传图片el-upload组件配置对象
      elUploadObject: {
            dataObj: {
                policy: '',
                signature: '',
                key: '',
                ossaccessKeyId: '',
                dir: '',
                host: '',
                // callback:'',
            },
            dialogVisible: false,
            useOss:true, //使用oss->true;使用MinIO->false
            ossUploadUrl:'http://eshop.oss-cn-shenzhen.aliyuncs.com',
            minioUploadUrl:'http://localhost:8080/minio/upload',
      },
      //新建分组绑定的相关数据
      newCategoryForm: Object.assign({},defaultCateForm),
      // 查询分组
      seachCategoryForm: {fileTypeName: ''},
      //新建分组表单校验规则
      categoryRule:{
          fileTypeName: [{  required: true, validator: validateFileTypeName, trigger: 'blur' }],
      },
      seachRule: {
          // fileTypeName: [{required: true}],
      },
      groupTextMap: {
          update: '修改',
          create: '新增'
      },
      groupDialogStatus: 'create',
      groupDialogVisible: false,
      seachDialogVisible: false,

      // 上传进度条
      // isShowProgress:false,
      // progressPercent:0,
      // progressStatusMsg:''
    }
  },
  computed: {},
  methods: {
    moveMaterial(index, type) {
      if (this.disabled) {
        this.$message.error('预览时暂时无法操作')
        return false
      }
      if (type == 'up') {
        const tempOption = this.value[index - 1]
        this.$set(this.value, index - 1, this.value[index])
        this.$set(this.value, index, tempOption)
        this.$emit('activeFatherComputed',this.value);
      }
      if (type == 'down') {
        const tempOption = this.value[index + 1]
        this.$set(this.value, index + 1, this.value[index])
        this.$set(this.value, index, tempOption)
        this.$emit('activeFatherComputed',this.value);
      }
    },
    zoomMaterial(index) {
        let viewersArray = this.$refs.materialViewerRef;
        let materialViewer = viewersArray[index].$viewer;
    
        if(this.type == 'image'){
            materialViewer.show();
        } else {
            this.dialogVisible = true
            this.url = this.value[index]
        }
      
    },
    deleteMaterial(index) {
      if (this.disabled) {
        this.$message.error('预览时暂时无法操作')
        return false
      }
      const that = this
      this.$confirm('是否确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function() {
        that.value.splice(index, 1)
        that.urls = []
        that.$emit('activeFatherComputed',that.value);
      }).catch((err) => {
        console.log(err);
      })
    },
    // 外部点击执行
    toSeleteMaterial() {
      if (this.isRechText) {
        this.value.splice(0)
      }
      this.seachCategoryForm.fileTypeName = "";
      if (this.disabled) {
        this.$message.error('预览时暂时无法操作')
        return false
      }
      this.listDialogVisible = true
      // 每次进来都重新加载.
      this.materialgroupPage();
      // if (this.tableData.length <= 0) {
      //   this.materialgroupPage()
      // }
    },
    materialgroupPage(id=null) {
      this.materialgroupLoading = true
      // let params = {
      //   total: 0, // 总页数
      //   currentPage: 1, // 当前页数
      //   pageSize: 100, // 每页显示多少条
      //   ascs: [], // 升序字段
      //   descs: 'create_time'// 降序字段
      // }
      materialgroupPage().then(response => {
        this.materialgroupLoading = false
        if(response.code === 0){
          const materialgroupList = response.data;
          materialgroupList.unshift({
            id: '0',
            fileTypeName: '全部分组'
          })
          this.materialgroupList = materialgroupList
          if (id) {
            this.resetSelected(id);
          } else {
            this.tabClick({index: 0})
          }
        }else{
        }
      })
    },
    materialgroupDelete(materialgroupObj) {
      const that = this
      this.$confirm('是否确认删除该分组？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function() {
        materialgroupDel(materialgroupObj.id).then((res) => {
          if(res.code === 0){
            that.$message({
              message: '删除成功',
              type: 'success'
            })
            that.$delete(that.materialgroupList, materialgroupObj.index)
          }else{
              that.$message({
                message: res.msg,
                type: 'warning'
              })
          }
        }).catch(err => {
          // that.$message({
          //   message: "未知错误，请联系系统管理员",
          //   type: 'error'
          // })
        })
          // .then(function() {
          //   that.$delete(that.materialgroupList, materialgroupObj.index)
          // })
      })
    },
    materialgroupEdit(materialgroupObj) {
      let formObject = {
        id: materialgroupObj.id,
        fileTypeName: materialgroupObj.fileTypeName,
        remark: materialgroupObj.remark ?  materialgroupObj.remark : '',
      }
      this.newCategoryForm = Object.assign({},formObject);
      this.groupDialogStatus = 'update';
      this.groupDialogVisible = true;
      this.$nextTick(() => {
          this.$refs['cateForm'].clearValidate()
      })
    },
    materialgroupAdd() {
      this.newCategoryForm = Object.assign({},defaultCateForm);
      this.groupDialogStatus = 'create';
      this.groupDialogVisible = true;
      this.$nextTick(() => {
          this.$refs['cateForm'].clearValidate()
      })
    },
     materialgroupSearch() {
      // this.newCategoryForm = Object.assign({},defaultCateForm);
      // this.groupDialogStatus = 'create';
      this.seachDialogVisible = true;
      // this.$nextTick(() => {
      //     this.$refs['cateForm'].clearValidate()
      // })
    },
    cancelCateDialog(){
      this.groupDialogVisible = false;
      this.newCategoryForm = Object.assign({},defaultCateForm);
    },
    // 查询关闭
    cancelSeachDialog(){
      this.seachDialogVisible = false;
      this.seachCategoryForm = {
        fileTypeName: ''
      }
      // this.newCategoryForm = Object.assign({},defaultCateForm);
    },
    //添加文件类型新分组
    submitAddCate(){
      // const that = this
      this.$refs.cateForm.validate((valid) => {
        if(valid){
          let params = Object.assign({},this.newCategoryForm);
          materialgroupAdd(params).then((res) => {
            if(res.code === 0){
                this.$message({
                  message: '添加分组成功',
                  type: 'success'
                });
                this.cancelCateDialog();
                this.materialgroupPage();
            }else{
              this.$message({
                message: res.msg,
                type: 'warning'
              })
            }
          }).catch((err) => {
              // this.$message({
              //   message: '未知错误，请联系系统管理员',
              //   type: 'error'
              // })
          })
        }else{
          this.$message({
            message: '请补充完表格再重新提交',
            type: 'warning'
          })
          return false;
        }
      })
    },
    //编辑已有文件类型分组内容
    submitEditCate(){
      this.$refs.cateForm.validate((valid) => {
        if(valid){
          let params = Object.assign({},this.newCategoryForm);
          materialgroupEdit(params).then((res) => {
            if(res.code === 0){
                this.$message({
                  message: '编辑分组成功',
                  type: 'success'
                });
                this.cancelCateDialog();
                this.materialgroupPage(params.id);
            }else{
              this.$message({
                message: res.msg,
                type: 'warning'
              })
            }
          }).catch((err) => {
              // this.$message({
              //   message: '未知错误，请联系系统管理员',
              //   type: 'error'
              // })
          })
        }else{
          this.$message({
            message: '请补充完表格再重新提交',
            type: 'warning'
          })
          return false;
        }
      })
    },
    //查询文件分组
    submitSeach(){
      this.$refs.seachForm.validate((valid) => {
        if(valid){
          materialgroupPage({
            fileTypeName: this.seachCategoryForm.fileTypeName
          })
          .then(res => {
              this.cancelSeachDialog();
              if(res.code === 0){
                const materialgroupList = res.data;
                materialgroupList.unshift({
                  id: '0',
                  fileTypeName: '全部分组'
                })
                this.materialgroupList = materialgroupList
                if(id){
                  this.resetSelected(id);
                }else{
                  this.tabClick({index: 0})
                }
              }else{
                this.$message({
                  message: res.msg,
                  type: 'warning'
                })
              }
          })
        }else{
          this.$message({
            message: '请补充完表格再重新提交',
            type: 'warning'
          })
          return false;
        }
      })
    },
    tabClick(tab, event) {
      this.urls = []
      const index = Number(tab.index)
      const materialgroupObj = this.materialgroupList[index]
      materialgroupObj.index = index
      this.materialgroupObj = materialgroupObj
      this.materialgroupObjId = String(materialgroupObj.id);
      this.page.currentPage = 1
      this.page.total = 0
      if (materialgroupObj.id != '-1') {
        this.groupId = materialgroupObj.id
      } else {
        this.groupId = null
      }
      this.getPage(this.page)
    },
    //编辑分组后重新获取数据后调用，重定向回编辑时的分组
    resetSelected(id){
      let materialgroupObj
      for(let i = 0,item; item = this.materialgroupList[i++];){
        if(item.id === id){
          materialgroupObj = item;
          materialgroupObj.index = i -1;
          break;
        }
      }
      this.materialgroupObj = materialgroupObj
      this.materialgroupObjId = String(materialgroupObj.id);
      this.page.total = 0
      if (materialgroupObj.id != '-1') {
        this.groupId = materialgroupObj.id
      } else {
        this.groupId = null
      }
      this.getPage(this.page)
    },
    getPage(page, params={}) {
      this.tableLoading = true
      getPage(Object.assign({
        current: page.currentPage,
        size: page.pageSize,
        descs: this.page.descs,
        ascs: this.page.ascs
      }, {
        fileTypeId: this.groupId,
        fileName: this.materialSearchForm.imageName
      },params)).then(response => {
        this.tableLoading = false;
        if(response.code === 0){
          const tableData = response.data.records;
          this.page.total = response.data.total;
          this.page.currentPage = page.currentPage;
          this.page.pageSize = page.pageSize;
          this.tableData = tableData;
        }
      }).catch(() => {
        this.tableLoading = false
      })
    },
    sizeChange(val) {
      this.page.currentPage = 1
      this.page.pageSize = val
      this.getPage(this.page)
    },
    pageChange(val) {
      this.page.currentPage = val
      // this.page.pageSize = val
      this.getPage(this.page)
    },
    materialRename(item) {
      const that = this
      this.$prompt('请输入素材名', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: item.name
      }).then(({ value }) => {
        putObj({
          id: item.id,
          name: value
        }).then(function() {
          that.getPage(that.page)
        })
      }).catch(() => {

      })
    },
    materialUrl(item) {
      const that = this
      this.$prompt('素材链接', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: item.url
      }).then(({ value }) => {

      }).catch(() => {

      })
    },
    materialDel(item) {
      const that = this;
      this.$confirm('是否确认删除该素材？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function() {
        delObj(item.id)
          .then(function() {
            that.getPage(that.page)
          })
      })
    },
    handleCommand(command) {
      const that = this
      const s = command.split('-')
      putObj({
        id: s[0],
        groupId: s[1]
      }).then(function() {
        that.getPage(that.page)
      })
    },
    //el-upload 文件上传时的钩子
    //自定义上传方法
    materialUpload(e){
      let that = this;
      let materialFile = e.file;
      let limit10M = materialFile.size / 1024 / 1024 < 500;

      if(limit10M){
        let fd = new FormData();
        fd.append('file',materialFile);
        fd.append('fileTypeId', this.materialgroupObjId);

        let action =null

        // 判断文件大小是否超过1M，如果超过1M 才显示进度条
        // if(materialFile.size / 1024 / 1024 > 1){
          // action=this.uploadProgress;
          // this.progressPercent=0
          // this.isShowProgress=true
          // this.progressStatusMsg='文件上传中,请勿关闭弹窗。'
        // }
        this.setUpClient().then(()=>{
          this.ossPutObject(materialFile).then(res=>{
            const obj ={
              size:materialFile.size,
              type:materialFile.type,
              fileTypeId:this.materialgroupObjId,
              url:res,
              fileName:materialFile.name
            }
            this.saveSysFile(obj).then(result=>{
              if(result.data.code === 0){
                that.page.currentPage = 1;
                that.getPage(that.page)
              }
            }).catch(err=>{
              this.uploadFail()
            })
          }).catch(err=>{
            this.uploadFail()
          })
        }).catch(err=>{
          this.uploadFail()
        })

        // uploadMaterialImage(fd,action).then((res) => {
        //   if(res.code === 0){
        //     that.page.currentPage = 1;
        //     that.getPage(that.page)
        //     if(this.isShowProgress){
        //       this.progressStatusMsg='文件上传完毕。'
        //       setTimeout(()=>{
        //         this.isShowProgress=false
        //       },500)
        //     }
        //   }
        // })
      }else{
        this.$message({
          message: '上传文件过大,上传文件不能超过500M',
          type: 'error'
        })
      }
    },
    uploadFail(){
      this.$message.error('上传文件失败')
    },
    uploadProgress(event){
      this.progressPercent = Math.round((event.loaded * 100) / event.total);
      if(this.progressPercent>=100){
        // this.progressStatusMsg='文件上传完毕!'
        // setTimeout(()=>{
        //   this.isShowProgress=false
        // },500)
      }
    },

    handleProgress(event, file, fileList) {
      // let uploadProgress = file.percentage.toFixed(0)
      // this.uploadProgress = uploadProgress
    },
    handleSuccess(response, file, fileList) {
      let url = this.elUploadObject.dataObj.host + '/' + this.elUploadObject.dataObj.dir + '/' + file.name;
      if(!this.elUploadObject.useOss){
          //不使用oss直接获取图片路径
          url = response.data.url;
      }
      this.urls.push(url);
      this.$set(this.value,item);
    //   多图片情况下使用方法
      this.urls.forEach(item => {
        this.$set(this.value, this.value.length, item)
      })
      this.listDialogVisible = false

    //   旧方法
    //   this.uploadProgress = 0
    //   addObj({
    //     type: '1',
    //     groupId: this.groupId != '-1' ? this.groupId : null,
    //     name: file.name,
    //     url: response.link
    //   }).then(function() {
    //     that.getPage(that.page)
    //   })
    },
    beforeUpload(file) {
      const isPic =
          file.type === 'image/jpeg' ||
          file.type === 'image/png' ||
          file.type === 'image/gif' ||
          file.type === 'image/jpg'
      const isLt10M = file.size / 1024 / 1024 < 10
      if (!isPic) {
        this.$message.error('上传图片只能是 JPG、JPEG、PNG、GIF 格式!')
        return false
      }
      if (!isLt10M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
        return false
      }
      //   return isPic && isLt10M
      let _self = this;
      if(!this.elUploadObject.useOss){
        //不使用oss不需要获取策略
        return true;
      }
      return new Promise((resolve, reject) => {
          policy().then(response => {
          _self.elUploadObject.dataObj.policy = response.data.data.policy;
          _self.elUploadObject.dataObj.signature = response.data.data.signature;
          _self.elUploadObject.dataObj.ossaccessKeyId = response.data.data.accessKeyId;
          _self.elUploadObject.dataObj.key = response.data.data.dir + '/${filename}';
          _self.elUploadObject.dataObj.dir = response.data.data.dir;
          _self.elUploadObject.dataObj.host = response.data.data.host;
          // _self.dataObj.callback = response.data.callback;
          resolve(true)
        }).catch(err => {
          reject(false)
        })
      })
    },
    beforeUploadFn (file) {
      const _isLimit= file.size / 1024 > this.limitSize;
      if (_isLimit) {
        this.$message.error('上传素材大小不能超过 ' + this.limitSize/1024 + ' MB !');
        return false
      }
      return true
    },
    sureUrls() {
      if(this.num > 1){
        this.urls.forEach(item => {
          this.$set(this.value, this.value.length, item)
        })
      }else{
        this.$set(this.value,0,this.urls[0]);
      }
      this.urls.splice(0,this.urls.length);
      this.$emit('activeFatherComputed',this.value);
      this.listDialogVisible = false
    },
    //搜索图片
    searchPicture(){
      let params = {
        fileName: this.materialSearchForm.imageName
      }
      this.page.currentPage = 1;
      this.getPage(this.page);
    },
    //重置图片搜索
    resetImageSearch(){
        this.materialSearchForm.imageName = null;
        this.page.currentPage = 1;
        this.getPage(this.page);
    },
    //在外层表单初始化后强制更新组件的值 避免组件值污染原始模型
    forceUpdateValue(){
      this.value.splice(0,this.value.length);
    },
  }
}
</script>

<style lang="scss" scoped>
  .pagination{
      display: flex;
      justify-content: center;
      margin-top: 24px;
  }
  .my-dialog /deep/{
    .el-dialog__body {
      padding: 10px 10px 2px 10px;
      font-size: 12px;
    }
    .el-tabs__nav-wrap::after{
      background-color: #fff;
    }
    .el-card__header {
      padding: 4px 15px;
      border-bottom: 1px solid #f0f2f4;
    }
  }

  .search-add-bar{
      display: flex;
      align-items: center;
      .material-search{
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
        .search-form-part{
            .el-form-item{
                margin-bottom: 0;
            }
        }
        .search-btn-part{
            margin-left: 10px;
            display: flex;
        }
      }
  }
  .material-select-card{
      margin: 5px;
      padding: 1px 5px 0;
      border: 1px solid #e6ebf5;
      box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
      .material-image-name{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 20px;
          margin-top: 5px;
          font-size: 12px;
      }
      .material-operate{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
      }
      .material-checkbox{
        padding: 8px 0px;
        font-size: 12px;
      }
  }
  .material-left-addbtn{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .material-left-tabs /deep/{
    .el-tabs__header.is-left{
      width: 100%;
    }
    .el-tabs__item {
      padding: 0 10px !important;
      padding: 0 20px;
      height: 36px;
      line-height: 36px;
      font-size: 13px;
      font-weight: 500;
    }
    .el-tabs__item.is-active{
      color: #fff;
      background-color: #409EFF;
    }
    .materialGroupLab{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .delete-edit-icon{
        color: #ffffff;
      }
    }
  }
  .col-do{
    text-align: center;
  }
  .button-do{
    padding: unset!important;
    font-size: 12px;
  }
  .el-upload-list__item-actions{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    .e-upload-list__item-actions__total-wrap{
      display: flex;
      flex-direction: column;;
      .el-upload-list__item-icon-wrap{
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
  .el-upload--picture-card {
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 1px;
  }

  .el-upload-list--picture-card .el-upload-list__item {
    background-color: #fff;
    border: 1px solid #c0ccda;
    border-radius: 1px;
    box-sizing: border-box;
    width: 148px;
    height: 148px;
    margin: 0 8px 8px 0;
    display: inline-block;
  }

  .no__margin{
    margin-bottom: 0 !important;
  }
  .special-single-class{
    // display: flex;
    // align-items: center;
    // vertical-align: middle;
    // justify-content: center;
  }

.disabledUpLoad {
  cursor: not-allowed;
}

.my-card{
  position: relative;
  .progress-box{
    position: absolute;
    left:0;top:0;
    width:100%;
    height:100%;
    display: flex;
    vertical-align: center;
    align-items: center;
    justify-content: center;
    z-index: 15;
    .progress-bg{
      display: block;
      position: absolute;
      left:0;
      top:0;
      width:100%;
      height:100%;
      background: #ccc;
      opacity: 0.8;
    }
  }
  .progress-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .progress-msg{
      font-size:28px;
      line-height: 48px;
    }
  }
}
</style>

