import 'babel-polyfill'
import 'classlist-polyfill'
import Vue from 'vue'
import VeHistogram from 'v-charts/lib/histogram.common'
import VeMap from 'v-charts/lib/map.common'
import VeLine from 'v-charts/lib/line.common'
import echarts from 'echarts'
import Viewer from 'v-viewer'
import 'xe-utils'
import axios from './router/axios'
import VXETable from 'vxe-table'
import 'vxe-table/lib/index.css'
import VueAxios from 'vue-axios'
import App from './App'
import './permission' // 权限
import '@/util/prototype.js'
// import './error' // 日志
import router from './router/router'
import store from './store'
import { loadStyle } from './util/util'
import * as urls from '@/config/env'
import { iconfontUrl, iconfontVersion } from '@/config/env'
import { dateStr, formatDateTime } from "@/util/chatUtils.js";
import * as filters from './filters' // 全局filter
import ElementUI from 'element-ui'
import 'view-design/dist/styles/iview.css';
import 'element-ui/lib/theme-chalk/index.css'
import './styles/common.scss'
import 'viewerjs/dist/viewer.css'
// import EVue from 'evue'
import basicContainer from './components/basic-container/main'
import evueForm from './components/evue-form/packages/index/index'
import evueTable from './components/evue-table/packages/index/index'
// 插件 json 展示

import AvueFormDesign from '@sscfaith/avue-form-design' // https://github.com/sscfaith/avue-form-design
import {
  CommonTable, // 表格
} from '@/components/'

/*
** 富文本编辑器 start
*/
import { ElementTiptapPlugin } from 'element-tiptap'; // https://github.com/Leecason/element-tiptap
import 'element-tiptap/lib/index.css';
import RichText from '@/components/richText/richText.vue'

Vue.use(ElementTiptapPlugin, { /* 插件配置项 */ });
Vue.component('RichText', RichText);
/*
** 富文本编辑器 end
*/

/**
 * 图片上传
 */
import MaterialList from "@/components/material";
Vue.component('MaterialList', MaterialList);

Vue.use(router)
Vue.use(VXETable)
Vue.use(VueAxios, axios);
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999,
  }
});
Vue.use(ElementUI, {
  size: 'mini',
  menuType: 'text'
})

// Vue.use(EVue);

Vue.use(window.AVUE, {
  size: 'mini',
  menuType: 'text'
})
Vue.use(AvueFormDesign)
Vue.use(CommonTable);


// 注册全局容器
Vue.component('basicContainer', basicContainer);
Vue.component(evueForm.name, evueForm);
Vue.component(evueTable.name, evueTable);
Vue.component(VeHistogram.name, VeHistogram);
Vue.component(VeMap.name, VeMap);
Vue.component(VeLine.name, VeLine);
// 加载相关url地址
Object.keys(urls).forEach(key => {
  Vue.prototype[key] = urls[key]
})

// 加载过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

// 动态加载阿里云字体库
iconfontVersion.forEach(ele => {
  loadStyle(iconfontUrl.replace('$key', ele))
})

/**
 * 权限指令
 */
// Vue.directive('has', {
//   bind: function(el, binding) {
//     if (!Vue.prototype.$_has(binding.value)) {
//       el.parentNode.removeChild(el)
//     }
//   }
// })

/**
 * 权限检查方法
 */
// Vue.prototype.$_has = function(value) {
//   const permissions = store.state.user.permissions
//   if (!permissions[value]) {
//     return false
//   }
//   return true
// }
// 数据类型判断
const typeOf = function (obj) {
  const toString = Object.prototype.toString
  const map = {
    '[object Date]': 'date',
    '[object Null]': 'null',
    '[object Array]': 'array',
    '[object Number]': 'number',
    '[object Object]': 'object',
    '[object RegExp]': 'regExp',
    '[object String]': 'string',
    '[object Boolean]': 'boolean',
    '[object Function]': 'function',
    '[object Undefined]': 'undefined',
    '[object HTMLCollection]': 'htmlCollection',
    '[object HTMLDivElement]': 'div',
    '[object HTMLSpanElement]': 'span'
  }
  return map[toString.call(obj)]
}
// 深克隆
const deepCopyFn = function (res) {
  const t = typeOf(res)
  let o = null
  if (t === 'array') {
    o = []
  } else if (t === 'object') {
    o = {}
  } else {
    return res
  }
  if (t === 'array') {
    for (let i = 0; i < res.length; i++) {
      o.push(deepCopyFn(res[i]))
    }
  } else if (t === 'object') {
    for (let i in res) {
      o[i] = deepCopyFn(res[i])
    }
  }
  return o
}
// 是否有值
const hasValueFn = function (val) {
  if (val === '' || val === null || val === undefined || val === 'null' || val === 'undefined') {
    return false
  } else {
    return true
  }
}
// 获取 数组交集, key 有值 则为数组对象[{}]，否则为 非数组对象[''] (建议 arr1 为 短数组, arr2 为长数组, 没有具体测试过不这么传值有何影响，不这么传值应该没啥问题)
const getArrayIntersectionSetFn = function (arr1 = [], arr2 = [], key = null) {
  let _res = null
  if (hasValueFn(key)) {
    _res = [...arr2].filter(x => [...arr1].some(y => y[key] === x[key]))
  } else {
    const a = new Set(arr1)
    const b = new Set(arr2)
    _res = Array.from(new Set([...b].filter(x => a.has(x))))
  }
  return _res
}
// 获取 数组差集
const getArrayDifferenceSetFn = function (arr1 = [], arr2 = [], key = null) {
  let _res = null
  if (key) {
    _res = [...arr2].filter(x => [...arr1].every(y => y[key] !== x[key]))
  } else {
    const a = new Set(arr1)
    const b = new Set(arr2)
    _res = Array.from(new Set([...b].filter(x => !a.has(x))))
  }
  return _res
}
// 数组去重, key 有值 则为数组对象[{}]，否则为 非数组对象[''] 
const getArrayDeduplicationFn = function (arr = [], key = null) {
  let _res = null
  if (hasValueFn(key)) {
    let _newArr = [] // 盛放去重后数据的新数组

    for (const _item1 of arr) {  // 循环j 数组对象的内容
      let flag = true;  // 建立标记，判断数据是否重复，true为不重复
      for (const _item2 of _newArr) {  // 循环新数组的内容
        if (_item1[key] === _item2[key]) { // 让 数组对象的内容与新数组的内容作比较，相同的话，改变标记为false
          flag = false
        }
      }
      if (flag) { //判断是否重复
        _newArr.push(_item1) //不重复的放入新数组。  新数组的内容会继续进行上边的循环。
      }
    }
    _res = _newArr
  } else {
    _res = Array.from(new Set(arr))
  }
  return _res
}

Vue.config.productionTip = false
Vue.prototype.winControl = require("@/mode/webControl").default;
Vue.prototype.formatDateTime = formatDateTime;
Vue.prototype.dateStr = dateStr;
Vue.prototype.$echarts = echarts;
Vue.prototype.$typeOf = typeOf;
Vue.prototype.$deepCopyFn = deepCopyFn;
Vue.prototype.$hasValueFn = hasValueFn;
Vue.prototype.$getArrayIntersectionSetFn = getArrayIntersectionSetFn;
Vue.prototype.$getArrayDifferenceSetFn = getArrayDifferenceSetFn;
Vue.prototype.$getArrayDeduplicationFn = getArrayDeduplicationFn;
/**
 * 权限指令
 */
// Vue.directive('has', {
//   bind: function(el, binding) {
//     if (!Vue.prototype.$_has(binding.value)) {
//       // console.log('parentNode',el);
//       el.parentNode.removeChild(el)
//     }
//   }
// })

/**
 * 权限检查方法
 */
// Vue.prototype.$_has = function(value) {
//   const permissions = store.state.user.permissions
//   if (!permissions[value]) {
//     return false
//   }
//   return true
// }

const $vue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
window.vm = $vue;
